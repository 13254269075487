import React from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';

import { useI18n } from 'services/i18n';
import media from 'styles/media';
import { Background, Col, Container, Row } from 'styles/grid';
import Layout from 'components/layout';
import SEO from 'components/seo';
import Block from 'components/block';
import Links from 'components/links';
import Customers from 'images/icons/customers.svg';
import Impact from 'images/icons/impact.svg';
import {
  BiggerText,
  PrimaryTitle,
  SecondaryTitle,
  Text,
  ParagraphTitle,
  ParagraphText,
} from 'components/texts';
import { SecondaryButton } from 'components/buttons';

import LogoOneStore from 'images/logos/onestore.svg';
import LogoAlme from 'images/logos/alme.svg';
import LogoAsphalte from 'images/logos/asphalte.svg';
import LogoBalzac from 'images/logos/balzac.svg';
import LogoBexley from 'images/logos/bexley.svg';
import LogoBensimon from 'images/logos/bensimon.svg';
import LogoBizzbee from 'images/logos/bizzbee.svg';
import LogoEdenPark from 'images/logos/edenpark.svg';
import LogoFreemanTPorter from 'images/logos/freemantporter.svg';
import LogoGaleries from 'images/logos/galerieslafayette.svg';
import LogoJules from 'images/logos/jules.svg';
import LogoKitsune from 'images/logos/maisonkitsune.svg';
import LogoLafaurie from 'images/logos/lafaurie.svg';
import LogoMudJeans from 'images/logos/mudjeans.svg';
import LogoShilton from 'images/logos/shilton.svg';
import LogoSpyder from 'images/logos/spyder.svg';
import LogoVolcom from 'images/logos/volcom.svg';
import LogoZespa from 'images/logos/zespa.svg';

const NumbersRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 20px 0;
  justify-content: center;
  svg {
    flex: 0 0 24px;
    height: 24px;
    width: 24px;
    margin-bottom: 0.5rem;
  }
  p {
    margin: 0;
  }

  ${media.md`
    flex-wrap: nowrap;
    justify-content: flex-start;

    svg {
      margin-right: 1rem;
      margin-bottom: 0;
    }
  `}
`;

const LogosContainer = styled(Row)`
  margin-top: 40px;
  svg {
    margin: 40px;
  }
`;

const SyledImpactCol = styled(Col)`
  margin-top: 60px;
  div {
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  p {
    margin: 10px 0;
  }
  ${media.md`
    margin-top: 100px;
  `}
`;

const StyledImpactLogo = styled.div`
  svg {
    max-height: ${props => props.maxHeight || '30px'};
  }
`;

const logosMapping = {
  edenpark: <LogoEdenPark />,
  jules: <LogoJules />,
  asphalte: <LogoAsphalte />,
  balzac: <LogoBalzac />,
  volcom: <LogoVolcom />,
  kitsune: <LogoKitsune />,
};

const ImpactCol = ({ t, logoFixed, title, text, number, maxHeight }) => (
  <SyledImpactCol sm={12} md={3.5}>
    {logoFixed && (
      <StyledImpactLogo maxHeight={maxHeight}>{logosMapping[logoFixed]}</StyledImpactLogo>
    )}
    <Text desktopAlign="center" weight="bold">
      {t(`clients.${title}`)}
    </Text>
    <Text desktopAlign="center">{t(`clients.${text}`)}</Text>
    <BiggerText desktopAlign="center" weight="bold" color="fitle">
      {t(`clients.${number}`)}
    </BiggerText>
  </SyledImpactCol>
);

const Clients = () => {
  const [t, language] = useI18n();

  const data = useStaticQuery(graphql`
    query {
      maisonkitsune: file(relativePath: { eq: "clients/maisonkitsune.png" }) {
        childImageSharp {
          fluid(maxWidth: 600, quality: 100) {
            ...GatsbyImageSharpFluid
            presentationWidth
          }
        }
      }
      shilton: file(relativePath: { eq: "clients/shilton.png" }) {
        childImageSharp {
          fluid(maxWidth: 600, quality: 100) {
            ...GatsbyImageSharpFluid
            presentationWidth
          }
        }
      }
      volcom: file(relativePath: { eq: "clients/volcom.png" }) {
        childImageSharp {
          fluid(maxWidth: 600, quality: 100) {
            ...GatsbyImageSharpFluid
            presentationWidth
          }
        }
      }
      pyrenex: file(relativePath: { eq: "clients/pyrenex.png" }) {
        childImageSharp {
          fluid(maxWidth: 600, quality: 100) {
            ...GatsbyImageSharpFluid
            presentationWidth
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO title={t('seo.title.clients')} lang={language} desc={t('seo.description.clients')} />
      <section>
        <Container>
          <Row justify="center" style={{ marginBottom: 40 }}>
            <Col md="content" style={{ textAlign: 'center' }}>
              <Customers height="4rem" width="4rem" />
            </Col>
          </Row>
          <Row justify="center">
            <Col sm={12} md={10} lg={8}>
              <PrimaryTitle align="center">{t('clients.title')}</PrimaryTitle>
            </Col>
          </Row>
          <Row justify="center">
            <Col sm={12} md={11} lg={10}>
              <BiggerText desktopAlign="center">{t('clients.market')}</BiggerText>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Maison Kitsune */}
      <Background style={{ display: 'inline-block' }}>
        <section>
          <Container>
            <Block
              titleText={t('clients.maisonkitsune.title')}
              imageFluid={data.maisonkitsune.childImageSharp.fluid}
              subtitle={t('clients.datas')}
              alt="image maisonkitsune"
              isImageLeft={true}
              link="/case-study-maisonkitsune"
            >
              <ParagraphTitle>{t('clients.maisonkitsune.collaboration-title')}</ParagraphTitle>
              <ParagraphText>{t('clients.maisonkitsune.collaboration-text')}</ParagraphText>
              <NumbersRow>
                <Impact />
                <Text>
                  {t('clients.feedbacks')}
                  <Text as="span" weight="bold">
                    {t('clients.experience')}
                  </Text>
                  <Text as="span" weight="bold" color="fitle">
                    {t('clients.maisonkitsune.experience-number')}
                  </Text>
                </Text>
              </NumbersRow>
              <NumbersRow>
                <Impact />
                <Text>
                  <Text as="span" weight="bold" color="fitle" style={{ marginLeft: '0px' }}>
                    {t('clients.maisonkitsune.turnover')}
                  </Text>
                  {t('clients.maisonkitsune.turnover-generated')}
                  <Text as="span" weight="bold">
                    {t('clients.maisonkitsune.fitle-recommendation')}
                  </Text>
                </Text>
              </NumbersRow>
              <SecondaryButton
                text={t('clients.in-depth-case-study')}
                to="/case-study-maisonkitsune"
              />
            </Block>
          </Container>
        </section>
      </Background>

      {/* Shilton */}
      <section>
        <Container>
          <Block
            titleText={t('clients.shilton.title')}
            imageFluid={data.shilton.childImageSharp.fluid}
            subtitle={t('clients.datas')}
            alt={t('seo.alt.clients.shilton')}
          >
            <ParagraphTitle>{t('clients.impact')}</ParagraphTitle>
            <ParagraphText>{t('clients.shilton.impact-text')}</ParagraphText>
            <ParagraphTitle>{t('clients.turnover')}</ParagraphTitle>
            <ParagraphText style={{ marginBottom: 40 }}>
              {t('clients.shilton.turnover-text')}
            </ParagraphText>
            <NumbersRow>
              <Impact />
              <Text>
                {t('clients.feedbacks')}
                <Text as="span" weight="bold">
                  {t('clients.experience')}
                </Text>
                <Text as="span" weight="bold" color="fitle">
                  {t('clients.shilton.experience-number')}
                </Text>
              </Text>
            </NumbersRow>
            <NumbersRow>
              <Impact />
              <Text>
                {t('clients.consumer')}
                <Text as="span" weight="bold">
                  {t('clients.profile')}
                </Text>
                <Text as="span" weight="bold" color="fitle">
                  {t('clients.shilton.profile-number')}
                </Text>
              </Text>
            </NumbersRow>
            <SecondaryButton text={t('clients.in-depth-case-study')} to="/case-study-shilton" />
          </Block>
        </Container>
      </section>

      {/* Pyrenex */}
      <Background style={{ display: 'inline-block' }}>
        <section>
          <Container>
            <Block
              titleText={t('clients.pyrenex.title')}
              imageFluid={data.pyrenex.childImageSharp.fluid}
              subtitle={t('clients.datas')}
              alt="image pyrenex"
              isImageLeft={true}
              link="/case-study-pyrenex"
            >
              <ParagraphTitle>{t('clients.pyrenex.problem')}</ParagraphTitle>
              <ParagraphText>{t('clients.pyrenex.problem-text')}</ParagraphText>
              <ParagraphTitle>{t('clients.pyrenex.solution')}</ParagraphTitle>
              <ParagraphText style={{ marginBottom: 40 }}>
                {t('clients.pyrenex.solution-text')}
              </ParagraphText>
              <SecondaryButton text={t('clients.in-depth-case-study')} to="/case-study-pyrenex" />
            </Block>
          </Container>
        </section>
      </Background>

      {/* Volcom */}
      <section>
        <Container>
          <Block
            titleText={t('clients.volcom.title')}
            imageFluid={data.volcom.childImageSharp.fluid}
            subtitle={t('clients.datas')}
            alt={t('seo.alt.clients.volcom')}
          >
            <Text>
              {t('clients.volcom.brand')}
              <Text as="span" weight="bold">
                {t('clients.volcom.stock')}
              </Text>
              {t('clients.volcom.sales')}
            </Text>
            <Text>
              <Text as="span" weight="bold">
                {t('clients.volcom.ranking')}
              </Text>
              {t('clients.volcom.size')}
            </Text>
            <NumbersRow>
              <Impact />
              <Text>
                {t('clients.feedbacks')}
                <Text as="span" weight="bold">
                  {t('clients.experience')}
                </Text>
                <Text as="span" weight="bold" color="fitle">
                  {t('clients.volcom.experience-number')}
                </Text>
              </Text>
            </NumbersRow>
            <NumbersRow>
              <Impact />
              <Text>
                <Text as="span" weight="bold" color="fitle">
                  {t('clients.volcom.profile-number')}
                </Text>
                {t('clients.volcom.profile-link')}
                <Text as="span" weight="bold">
                  {t('clients.volcom.consumer')}
                </Text>
                {t('clients.volcom.profile')}
              </Text>
            </NumbersRow>
            <SecondaryButton text={t('clients.in-depth-case-study')} to="/case-study-volcom" />
          </Block>
        </Container>
      </section>

      <Background>
        <section>
          <Container style={{ padding: '80px 0' }}>
            <Row justify="center">
              <Col sm="content" md="content">
                <SecondaryTitle style={{ marginBottom: 0 }}>{t('clients.title2')}</SecondaryTitle>
              </Col>
            </Row>
            <Row justify="space-around" align="center">
              <ImpactCol
                t={t}
                logoFixed="kitsune"
                title="kitsune-title"
                text="kitsune-text"
                number="kitsune-number"
              />
              <ImpactCol
                t={t}
                logoFixed="edenpark"
                title="missed"
                text="customers"
                number="follow"
              />
              <ImpactCol
                t={t}
                logoFixed="jules"
                title="recommendation"
                text="height"
                number="of"
                maxHeight="20px"
              />
              <ImpactCol
                t={t}
                logoFixed="asphalte"
                title="conversion"
                text="surrender"
                number="returns"
                maxHeight="20px"
              />
              <ImpactCol t={t} logoFixed="balzac" title="purchase" text="advice" number="their" />
              <ImpactCol
                t={t}
                logoFixed="volcom"
                title="spent"
                text="global"
                maxHeight="55px"
                number="visitors"
              />
            </Row>
          </Container>
        </section>
      </Background>
      <section>
        <Container>
          <Row justify="center">
            <Col sm="content" md="content">
              <SecondaryTitle>{t('clients.average')}</SecondaryTitle>
            </Col>
          </Row>
          <LogosContainer justify="space-around" align="center">
            <LogoKitsune width="150px" height="40px" />
            <LogoAsphalte width="100px" height="30px" />
            <LogoGaleries width="120px" height="80px" />
            <LogoJules width="100px" height="30px" />
            <LogoShilton width="150px" height="110px" />
            <LogoVolcom width="100px" height="70px" />
            <LogoBalzac width="100px" height="30px" />
            <LogoAlme width="100px" height="40px" />
            <LogoOneStore width="100px" height="50px" />
            <LogoBexley width="100px" height="30px" />
            <LogoBizzbee width="100px" height="30px" />
            <LogoLafaurie width="100px" height="30px" />
            <LogoEdenPark width="100px" height="30px" />
            <LogoZespa width="100px" height="30px" />
            <LogoFreemanTPorter width="100px" height="50px" />
            <LogoBensimon width="100px" height="30px" />
            <LogoMudJeans width="100px" height="30px" />
            <LogoSpyder width="120px" height="80px" />
          </LogosContainer>
        </Container>
      </section>
      <Links withTitle={true} links={['plugin', 'dashboard']} />
    </Layout>
  );
};

export default Clients;
